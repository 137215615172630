import React, { useEffect, useState } from "react";
import noImage from "../../img/noImage.png";
import Drop from "../../components/Drop/Drop";
import store from "../../MobX";
import { Link } from "react-router-dom";
import { FILES_URL } from "../../jsCommon/constants";
import { observer } from "mobx-react-lite";
import api from "../../jsCommon/api";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { decodeString } from "../../funcs";
import { GIFT_SUM_ARR } from "../../jsCommon/common";
import SertTextPic from "../../pages/product/ProductDescr/SertBlock/__text-pic/SertTextPic";
import { GIFT_DATA_PRODS } from "./giftPageData";

const delivToImg = {
  "000015": "/img/delivery/cdek.png",
  "000014": "/img/delivery/cdek.png",
  "000008": "/img/delivery/ems.png",
  "000005": "/img/delivery/pr.png",
  "000006": "/img/delivery/dpd.png",
  "000002": "/img/delivery/pp.png",
  "000003": "/img/delivery/mf.png",
  "000004": "/img/delivery/mf.png",
  "000001": "/img/delivery/mf.png",
};

const CartCard = observer(({ isOrdered, prod, orderProd, prodsLoaded }) => {
  const [openChange, setOpenChange] = useState(false);
  const [alternativeImg, setAlternativeImg] = useState(noImage);
  const [img, setImg] = useState(
    prod &&
      prod.gruppaPoCvetu &&
      prod?.gruppaPoCvetu?.izobrazheniya.length &&
      prod?.gruppaPoCvetu?.izobrazheniya[0]?.formats?.medium?.url
      ? FILES_URL + prod?.gruppaPoCvetu.izobrazheniya[0]?.formats.medium.url
      : prod && prod.vneshnieIzobrazheniya
      ? prod.vneshnieIzobrazheniya.split(";")[0]
      : alternativeImg
  );
  const [isGiftCard, setIsGiftCard] = useState(false);
  const options = [];

  useEffect(() => {
    if (
      !(
        prod &&
        prod.gruppaPoCvetu &&
        prod?.gruppaPoCvetu?.izobrazheniya.length &&
        prod?.gruppaPoCvetu.izobrazheniya[0]?.formats?.medium?.url
      ) &&
      prodsLoaded &&
      isOrdered &&
      orderProd?.article &&
      !delivToImg[orderProd.article]
    ) {
      api
        .getProductImageMC(orderProd.article)
        .then((result) => {
          setAlternativeImg(result.link);
        })
        .catch((err) => {
          console.error("err :>> ", err);
        });
    }
  }, [orderProd?.article, prodsLoaded]);

  useEffect(() => {
    setImg(
      prod &&
        prod.gruppaPoCvetu &&
        prod?.gruppaPoCvetu?.izobrazheniya.length &&
        prod?.gruppaPoCvetu?.izobrazheniya[0]?.formats?.medium?.url
        ? FILES_URL + prod?.gruppaPoCvetu.izobrazheniya[0]?.formats.medium.url
        : prod && prod.vneshnieIzobrazheniya
        ? prod.vneshnieIzobrazheniya.split(";")[0]
        : alternativeImg
    );

    if (prod?.cardname?.includes("Подарочная страница")) {
      if (!isGiftCard) setIsGiftCard(true);
    } else setIsGiftCard(false);
  }, [prod]);

  if (!isOrdered)
    prod.gruppaPoCvetu.razmery.map((razmer) => {
      if (
        razmer.allwarehousesavailablecorrected > 0 &&
        !razmer.putinarchive &&
        razmer._id !== prod._id
      )
        options.push({
          size: razmer.filtersize,
          // addSize: "(52/54 RUS)",
          available: razmer.allwarehousesavailablecorrected > 0,
          wait: false,
          data: razmer,
          isSmall: true,
        });
    });
  const onChangeFuncSize = (size) => {
    const newCart = { ...store.prodInCart };
    delete newCart[prod.productcode];
    newCart[size.productcode] = {
      ...size,
      gruppaPoCvetu: prod.gruppaPoCvetu,
      countInCart: 1,
    };

    store.prodInCart = { ...newCart };
  };

  const counter = isOrdered ? null : (
    <div className="input--amount">
      <button
        className="prod-descr__cart-amount-minus i-minus"
        onClick={() => {
          if (prod.countInCart > 1) {
            prod.countInCart--;
          }
        }}
      ></button>
      <input
        className="input--amount__input"
        readOnly
        value={prod.countInCart}
      />
      <button
        className="prod-descr__cart-amount-plus i-plus"
        onClick={() => {
          if (prod.countInCart < 100) prod.countInCart++;
        }}
      ></button>
    </div>
  );

  // const options = [
  //   { size: "S", addSize: "(52/54 RUS)", available: true, wait: false },
  //   { size: "M", addSize: "(54/56 RUS)", available: true, wait: false },
  //   { size: "2XL", addSize: "(64/66 RUS)", available: true, wait: false },
  // ];

  const isMobile = store.isMobile;

  let orderProdDiscount = 0;
  if (orderProd && orderProd.discount > 0 && orderProd.discount <= 1) {
    orderProdDiscount = orderProd.discount;
  } else if (orderProd && orderProd.discount > 1) {
    orderProdDiscount = orderProd.discount / 100;
  }

  let imageLink = img;

  if (
    isOrdered &&
    imageLink === alternativeImg &&
    delivToImg[orderProd.article]
  ) {
    imageLink = delivToImg[orderProd.article];
  }

  const pinText = prod ? store.getPinText(prod.deliverypin) : undefined;

  const chooseGiftSum = !isOrdered ? (
    <div className="cart-card__choose">
      <div className="sert-block-choose-sum">
        {GIFT_SUM_ARR.map((price) => (
          <div
            className={`btn btn_sm ${
              prod.priceretail === price ? "btn_primary" : "btn_secondary"
            }`}
            onClick={() => {
              const group = { ...prod.gruppaPoCvetu };

              const newCart = { ...store.prodInCart };
              delete newCart[prod.productcode];
              newCart[GIFT_DATA_PRODS[price].productcode] = {
                ...prod,
                ...GIFT_DATA_PRODS[price],
              };
              newCart[GIFT_DATA_PRODS[price].productcode].gruppaPoCvetu = {
                ...group,
                ...GIFT_DATA_PRODS[price].gruppaPoCvetu,
              };
              store.prodInCart = { ...newCart };
            }}
          >
            {price.toLocaleString()}₽
          </div>
        ))}
      </div>
    </div>
  ) : null;

  const editTextGift =
    !isOrdered && isGiftCard ? (
      <SertTextPic
        text={prod.text}
        imageURI={prod.imageURI}
        setText={(newText) => {
          store.prodInCart[prod.productcode].text = newText;
          store.prodInCart[prod.productcode] = {
            ...store.prodInCart[prod.productcode],
          };
        }}
        setImageURI={(newImageURI) => {
          store.prodInCart[prod.productcode].imageURI = newImageURI;
          store.prodInCart[prod.productcode] = {
            ...store.prodInCart[prod.productcode],
          };
        }}
      />
    ) : null;

  return (
    <div className="cart-card">
      <div className="row clean">
        <div className="col col-3 col-s-4">
          {/* <Link to={`/product/${prod.gruppaPoCvetu.slug}?${prod.filtersize}`}> */}
          <LazyLoadImage
            className="cart-card__img"
            src={imageLink}
            onError={async () => setImg(noImage)}
          />
          {/* </Link> */}
        </div>
        <div className="col col-9 col-s-8 cart-card__desc">
          <div className="cart-card__title">
            {prod &&
            !prod.putinarchive &&
            ((prod.gruppaPoCvetu &&
              prod?.gruppaPoCvetu?.izobrazheniya.length &&
              prod?.gruppaPoCvetu.izobrazheniya[0]?.formats?.medium?.url) ||
              prod.vneshnieIzobrazheniya !== undefined) &&
            prod.allwarehousesavailablecorrected > 0 &&
            !prod.productdisabled ? (
              <Link
                to={`/product/${prod.gruppaPoCvetu.slug}?${prod.filtersize}`}
                className="p lh-0"
              >
                {decodeString(prod.cardname)}{" "}
                {isGiftCard && prod
                  ? prod.priceretail?.toLocaleString() + "₽"
                  : ""}
              </Link>
            ) : orderProd ? (
              <p className="p lh-0">{decodeString(orderProd.name)}</p>
            ) : null}
            {isOrdered ? null : (
              <button
                className="cart-card__close"
                onClick={() => {
                  delete store.prodInCart[prod.productcode];
                  try {
                    window.dataLayer.push({
                      event: "removeFromCart",
                      ecommerce: {
                        currencyCode: "RUB",
                        remove: {
                          products: [
                            {
                              id: prod.productcode,
                              name: prod.cardtitle,
                              brand: prod.brand,
                              category: `${prod.propertygrouprus}/${prod.propertyproductcategoryrus}/${prod.propertyproductsubcategoryrus}`,
                              price: prod.pricesaleretail,
                              quantity: prod.countInCart,
                            },
                          ],
                        },
                      },
                    });
                  } catch (error) {
                    console.error("error :>> ", error);
                  }
                }}
              >
                <i className="i-close" />
              </button>
            )}
          </div>
          <div className="cart-card__info">
            {prod?.cardcolour && (
              <p className="">
                Цвет:{" "}
                <span className="cart-card__info-color-value">
                  {prod.cardcolour}
                </span>
              </p>
            )}
            {!isGiftCard
              ? isOrdered || isMobile || !options.length
                ? prod?.filtersize !== "Без размера" &&
                  prod?.filtersize && (
                    <p className="">
                      Размер:{" "}
                      <span className="cart-card__info-color-value">
                        {prod.filtersize}
                      </span>
                    </p>
                  )
                : prod.filtersize !== "Без размера" && (
                    <Drop
                      options={options}
                      def={{
                        size: prod.filtersize,
                        // addSize: "(52/54 RUS)",
                        available: prod.allwarehousesavailablecorrected > 0,
                        wait: false,
                        data: prod,
                        isSmall: true,
                      }}
                      onChangeFunc={onChangeFuncSize}
                    />
                  )
              : null}

            {!isGiftCard && (
              <p className="count-in-cart">
                Кол-во:{" "}
                <span className="cart-card__info-color-value">
                  {prod
                    ? prod.countInCart
                    : orderProd
                    ? orderProd.quantity
                    : ""}
                </span>
              </p>
            )}

            {isGiftCard && (
              <div className="cart-card__choose-price-gift">
                <p className="cart-card__price-gift">
                  Номинал:{" "}
                  <span>
                    {prod
                      ? prod.priceretail?.toLocaleString()
                      : orderProd
                      ? orderProd.priceretail?.toLocaleString()
                      : ""}
                    ₽
                  </span>
                  {!isMobile && !isOrdered && (
                    <i
                      className="i i-down"
                      onClick={(e) => {
                        document
                          .querySelector("#giftChangeSum")
                          .classList.toggle("open");
                        e.target.classList.toggle("active");
                      }}
                    ></i>
                  )}
                </p>

                <div className="close-block" id="giftChangeSum">
                  {chooseGiftSum}
                </div>

                {!isMobile && !isOrdered && (
                  <>
                    <p className="cart-card__price-gift">
                      Текст и фото:{" "}
                      <i
                        className="i i-down"
                        onClick={(e) => {
                          document
                            .querySelector("#giftChangeText")
                            .classList.toggle("open");
                          e.target.classList.toggle("active");
                        }}
                      ></i>
                    </p>
                    <div className="close-block" id="giftChangeText">
                      <div className="container">
                        <div className="row">{editTextGift}</div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <div>
            {!isOrdered && pinText ? (
              <div className="pin pin_inline pin_cart">
                {pinText?.toLowerCase()}
              </div>
            ) : null}
            {!isOrdered &&
            prod &&
            prod.gruppaPoCvetu &&
            prod.gruppaPoCvetu.predzakaz ? (
              <div className="pin pin_inline pin_cart">предзаказ</div>
            ) : null}
          </div>

          <div className="cart-card__foot">
            {orderProd ? (
              <div className="cart-card__price">
                {orderProd.discount > 0 && (
                  <p className="cart-card__price-old">
                    {Math.round(orderProd.price).toLocaleString()} ₽{" "}
                  </p>
                )}
                <p className="cart-card__price-new">
                  {Math.round(
                    orderProd.price * (1 - orderProdDiscount)
                  ).toLocaleString()}{" "}
                  ₽{" "}
                </p>
              </div>
            ) : (
              !isGiftCard && (
                <div className="cart-card__price">
                  {prod.priceretail !== prod.pricesaleretail && (
                    <p className="cart-card__price-old">
                      {prod.priceretail.toLocaleString()} ₽{" "}
                    </p>
                  )}
                  <p className="cart-card__price-new">
                    {prod.pricesaleretail.toLocaleString()} ₽{" "}
                  </p>
                </div>
              )
            )}
            {isMobile && !orderProd ? (
              <div
                className="cart-card__change"
                onClick={() => setOpenChange(!openChange)}
              >
                {openChange ? "Сохранить" : "Изменить"}
                {/* <i className="i-down" /> */}
              </div>
            ) : isOrdered || isGiftCard ? null : (
              counter
            )}
          </div>
        </div>
        {isMobile &&
          openChange &&
          (!isGiftCard ? (
            <div className="cart-card__change-mobile">
              {options.length > 0 && (
                <Drop
                  options={options}
                  def={{
                    size: prod.filtersize,
                    // addSize: "(52/54 RUS)",
                    available: prod.allwarehousesavailablecorrected > 0,
                    wait: false,
                    data: prod,
                  }}
                  onChangeFunc={onChangeFuncSize}
                />
              )}
              {counter}
            </div>
          ) : (
            <>
              {chooseGiftSum} {editTextGift}
            </>
          ))}
      </div>
    </div>
  );
});

export default CartCard;
