import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import CallbackModal from "../CallbackModal/CallbackModal";
import logo from "../../img/logofoot.svg";
import store from "../../MobX";
import { observer } from "mobx-react-lite";

const Header = observer(
  ({ updateOverlay, noScroll, setNoScroll, setMobileMenu, mobileMenu }) => {
    const { showModal, isModalType } = store;
    const isMobile = store.checkWidth[1000];

    const [showCallbackModal, setShowCallbackModal] = useState(false);
    const closeCallbackModal = () => {
      setShowCallbackModal(false); //закрываем модалку обратной связи
      updateOverlay(false);
      setNoScroll(false);
    };
    document.addEventListener("click", (e) => {
      if (e.target.classList.contains("overlay")) {
        setShowCallbackModal(false);
        updateOverlay(false);
        setNoScroll(false);
      }
    });
    let cartLength = store.prodInCart
      ? Object.keys(store.prodInCart).length
      : 0;
    return (
      <header className="header">
        <div className="container header__container">
          {/* {showCallbackModal ? (
          <CallbackModal closeCallbackModal={closeCallbackModal} />
        ) : null} */}
          <div className="header__left">
            <NavLink
              to="/cart"
              className="header__cart"
              onClick={() => {
                // if (isMobile) {
                //   setNoScroll(!noScroll);
                // }
              }}
            >
              <i className="i-cart-off" />
              {cartLength > 0 && (
                <span className="cart-btn__counter">
                  {cartLength > 9 ? 9 : cartLength}
                </span>
              )}
            </NavLink>

            <NavLink
              to="/"
              className="header__left-logo"
              onClick={() => {
                if (isMobile) {
                  setNoScroll(false);
                }
              }}
            >
              {/* <i className="i-logo" /> */}
              <img src={logo}></img>
            </NavLink>

            <span
              className="header__left-burger"
              onClick={() => {
                if (store.backInMobMenu === "open") {
                  store.backInMobMenu = "back";
                } else {
                  setNoScroll(!noScroll);
                  setMobileMenu(!mobileMenu);
                }
              }}
            >
              <i
                className={`${
                  noScroll
                    ? store.backInMobMenu !== null
                      ? "i-arrow"
                      : "i-close"
                    : "i-menu"
                } header__left-burger`}
              />
            </span>

            <ul className="header__left-list">
              <li className="header__left-item">
                <NavLink to="/service/pay" className="header__left-item-link">
                  Оплата и Доставка
                </NavLink>
              </li>
              <li className="header__left-item">
                <NavLink
                  to="/service/warranty"
                  className="header__left-item-link"
                >
                  Гарантия и Возврат
                </NavLink>
              </li>
              <li className="header__left-item">
                <NavLink
                  to="/service/bonuse"
                  className="header__left-item-link"
                >
                  Бонусы
                </NavLink>
              </li>
              <li className="header__left-item">
                <NavLink to="/service/shop" className="header__left-item-link">
                  Магазин
                </NavLink>
              </li>
              <li className="header__left-item">
                <NavLink
                  to="/catalog/podarochnye-karty"
                  className="header__left-item-link"
                >
                  Подарочные карты
                </NavLink>
              </li>
              <li className="header__left-item">
                <a href="/blog" className="header__left-item-link">
                  Блог
                </a>
              </li>
            </ul>
          </div>
          <div className="header__right">
            <button
              className="btn btn_primary btn_sm"
              onClick={() => {
                store.showModal = true;
                store.isModalType = { callback: true };
              }}
            >
              Связаться
            </button>
            <a href="tel:+74995530717" className="header__right-phone">
              +7 499 553-07-17
            </a>
          </div>
        </div>
      </header>
    );
  }
);

export default Header;
