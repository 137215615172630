import React from "react";
import noImage from "../../../../../img/noImage.png";

/**
 *
 * @param {{text:string, imageURI:string, setText:()=>void, setImageURI:()=>void}} param0
 * @returns
 */
export default function SertTextPic({ text, imageURI, setText, setImageURI }) {
  return (
    <>
      <div className="col col-8 col-s-12 sert-block__text">
        <div className="input-block">
          <label for="text">Введите текст поздравления</label>
          <textarea
            className=" "
            id="text"
            type="text"
            value={text}
            onChange={(e) => setText(e.target.value)}
            placeholder="Ваша почта"
          />
        </div>
      </div>
      <div className="col col-4 col-s-12 sert-block__photo">
        <div
          className={`sert-block__load-photo ${
            imageURI ? "" : "sert-block__load-photo_clear"
          }`}
        >
          <img
            src={imageURI ? imageURI : noImage}
            alt="Изображение не загружено"
          />
        </div>
        <button
          className={`sert-block__btn ${
            imageURI ? "sert-block__btn_delete" : ""
          } btn`}
          onClick={(e) => {
            if (imageURI) {
              setImageURI(null);
            } else e.currentTarget.querySelector("input").click();
          }}
        >
          {!imageURI ? "Добавить фото" : "Удалить фото"}
          <input
            className="sert-block__btn"
            type="file"
            name="loadFile"
            style={{ display: "none" }}
            accept="image/jpeg,image/png,image/svg+xml"
            onChange={(e) => {
              const file = e.target.files[0];
              //   setImage(file);
              const fileReader = new FileReader();
              //   const fileReaderBuffer = new FileReader();
              // Инициируем функцию FileReader
              fileReader.onload = function (file) {
                setImageURI(this.result);
                //  return function(e) {
                //     // Помещаем URI изображения в массив

                //  };
              };
              //   fileReaderBuffer.onload = function (file) {
              //     var arrayBuffer = this.result;

              //     // array = new Uint8Array(arrayBuffer),
              //     // binaryString = String.fromCharCode.apply(null, array);

              //     setImageBuffer(arrayBuffer);
              //   };
              // Производим чтение картинки по URI
              fileReader.readAsDataURL(file);
              //   fileReaderBuffer.readAsArrayBuffer(file);
            }}
          ></input>
        </button>
      </div>
    </>
  );
}
