import React, { useEffect, useState } from "react";
import CartCard from "../../components/CartCard/CartCard";

import SimpleBar from "simplebar-react";
import "simplebar/dist/simplebar.min.css";
import { Link } from "react-router-dom";

import store from "../../MobX";
import { observer } from "mobx-react-lite";

const CartModal = observer(({ closeCallbackModal }) => {
  const { showCartModal, prodInCart, sumInCart, saleValue, bonusValue } = store;

  return (
    <div className="cart-modal">
      <div className="cart-modal__top">
        <h4 className="cart-modal__title">
          Корзина:
          <span className="cart-modal__amount">
            {Object.values(prodInCart).length} товара
          </span>
        </h4>
        <h4 className="cart-modal__price">
          {(sumInCart - saleValue).toLocaleString()} ₽
        </h4>
      </div>
      <SimpleBar
        className="cart-modal__content"
        style={{ maxHeight: "600px" }}
        autoHide={false}
      >
        {Object.values(prodInCart).map((prod) => (
          <CartCard prod={prod} />
        ))}
      </SimpleBar>
      <div className="cart-modal__footer df">
        <Link
          to="/cart"
          className="btn btn_secondary mr10"
          onClick={() => (store.showCartModal = false)}
        >
          Перейти в корзину
        </Link>

        <Link
          to="/checkout"
          className="btn btn_primary"
          onClick={() => (store.showCartModal = false)}
        >
          Оформить заказ
        </Link>
      </div>
    </div>
  );
});

export default CartModal;
