import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import store from "../MobX";

const GIFT_SUM_ARR = [10000, 25000, 50000, 75000, 100000];

/**
 * @description Проверка, меньше ли ширина экрана, чем переданное значение
 * @param {number} startWidth Ширина, которую сравнивать с шириной экрана
 * @returns {boolean}
 */
const useCheckWidth = (startWidth) => {
  const [width, setWidth] = useState(startWidth);
  const [isSmall, setIsSmall] = useState(store.windowWidth < startWidth);
  useEffect(() => {
    setIsSmall(store.windowWidth < width);
  }, [store.windowWidth, width]);

  return isSmall;
};

const useServiceSEO = (defaultTitle, defaultDescription, defaultKeywords) => {
  const [title, setTitle] = useState(defaultTitle);
  const [description, setDescription] = useState(defaultDescription);
  const [keywords, setKeywords] = useState(defaultKeywords);
  const location = useLocation();

  useEffect(() => {
    const seo = store.serviceSEO[location.pathname];

    if (seo) {
      if (seo.title) setTitle(seo.title);
      if (seo.description) setDescription(seo.description);
      if (seo.keywords) setKeywords(seo.keywords);
    }
  }, [store.serviceSEO, location]);

  return [title, description, keywords];
};

export { useCheckWidth, useServiceSEO, GIFT_SUM_ARR };
