import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import api from "../../../jsCommon/api";
import store from "../../../MobX";
import Inputmask from "inputmask";

const Callback = ({}) => {
  const [callbackEmail, setCallbackEmail] = useState(false);
  const [isSuccses, setIsSuccses] = useState(null);
  const [personal, setPersonal] = useState(true);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({ mode: "onChange" });

  useEffect(() => {
    if (isSuccses !== null) {
      setTimeout(() => {
        setIsSuccses(null);
      }, 3000);
    }
  }, [isSuccses]);

  useEffect(() => {
    if (document.querySelector("#callback-phone")) {
      const tel = new Inputmask({
        mask: "+7 (999) 999 99 99",
        showMaskOnHover: false,
        // isComplete: function()
      });

      tel.mask(document.querySelector("#callback-phone"));
    } else {
      Inputmask.remove(document.querySelector("#callback-email"));
    }
  }, [callbackEmail]);

  const button = useRef(null);

  const onSubmit = async (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (!personal) {
      return;
    }
    button.current.classList.add("deactive");
    let city = "null";
    let ip = "null";
    await api
      .getCity()
      .then((result) => {
        city = `${result.country_name} ${result.state} ${result.city}`;
        ip = result.IPv4;
      })
      .catch((err) => {});

    api
      .sendQuestion({
        data: { ...data, typeContact: callbackEmail ? "email" : "phone" },
        serviceInfo: {
          pageURL: window.location.href,
          timeZoneClient: new Date().getTimezoneOffset(),
          browserInfo: navigator.userAgent,
          city,
          ip,
        },
      })
      .then((result) => {
        setIsSuccses(true);
        setTimeout(() => {
          store.showModal = false;
          store.isModalType = {};
        }, 2000);
      })
      .catch((err) => {
        setIsSuccses(false);
        console.error("err :>> ", err);
      })
      .finally(
        () => button.current && button.current.classList.remove("deactive")
      );
  };

  let classButton =
    isSuccses === null ? "" : isSuccses === true ? "success" : "fail";

  return (
    <>
      <form className="modal-window__form" onSubmit={handleSubmit(onSubmit)}>
        <label className="label-wrp" for="callback-name">
          Имя
          <input
            id="callback-name"
            type="text"
            {...register("name", { required: true })}
          />
        </label>
        <label className="label-wrp mt20" for="callback-question">
          Вопрос
          <textarea
            className="modal-window__input"
            id="callback-question"
            {...register("question", { required: true })}
          />
        </label>

        <div className="modal-window__answer">
          <p className="">Ответить на:</p>

          <label className="radio" for="radio-phone">
            <input
              className="modal-window__radio-input"
              type="radio"
              name="callback-type"
              id="radio-phone"
              checked={!callbackEmail}
              onChange={() => {
                setCallbackEmail(false);
                setValue("contact", "");
              }}
            />
            Телефон
          </label>

          <label className="radio" for="radio-email">
            <input
              className="modal-window__radio-input"
              type="radio"
              name="callback-type"
              id="radio-email"
              checked={callbackEmail}
              onChange={() => {
                setCallbackEmail(true);
                setValue("contact", "");
              }}
            />
            E-mail
          </label>
          {callbackEmail ? (
            <>
              <label className="label-wrp mt20" for="callback-email">
                E-mail
                <input
                  className="modal-window__input"
                  id="callback-email"
                  type="email"
                  {...register("contact", {
                    required: true,
                    pattern: /^\S+@\S+$/i,
                  })}
                />
              </label>
            </>
          ) : (
            <>
              <label className="label-wrp mt20" for="callback-phone">
                Телефон
                <input
                  className="modal-window__input"
                  id="callback-phone"
                  type="tel"
                  {...register("contact", {
                    required: true,
                    pattern: /\+7 \(\d{3}\) \d{3} \d{2} \d{2}/i,
                  })}
                />
              </label>
            </>
          )}
        </div>

        <button
          className={`modal-window__btn btn btn_primary ${classButton}`}
          type="submit"
          ref={button}
        >
          Отправить
        </button>
        <input
          type="checkbox"
          id="callback-access"
          className="modal-window__checkbox-input"
          checked={personal}
          onChange={(e) => setPersonal(e.target.checked)}
        />
        <label for="callback-access">
          Я принимаю{" "}
          <a href="/service/personal-data" target="_blank" rel="noreferrer">
            "Cоглашение об обработке персональных данных"
          </a>
        </label>
      </form>
    </>
  );
};

export default Callback;
