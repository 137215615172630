import React, { useEffect, useState } from "react";
import { parseEditorJsToDesc, returnSizeTableData } from "../../../funcs";

import store from "../../../MobX";

const Advice = ({}) => {
  const { modalData } = store;
  console.log("modalData", modalData);
  const textHTML = parseEditorJsToDesc(modalData.adviceHtml);

  return (
    <>
      <div className="modal-window__size">
        <noindex className="rich-text">{textHTML}</noindex>
        {/* <p>
          Таблицы размеров взяты с официальных сайтов или каталогов
          производителя. Продавец не несет ответсвенности за достоверность
          представленной информации. Во время согласования заказа мы
          дополнительно уточняем параметры рост и вес и даем рекомендации. В
          ряде случаев, если товар представлен в нашем шоу-руме мы можем
          прислать дополнительные фотографии товара на наших сотрудниках, схожих
          по параметрам на вас. Таблицы размеров взяты с официальных сайтов или
          каталогов производителя. Продавец не несет ответсвенности за
          достоверность представленной информации. Во время согласования заказа
          мы дополнительно уточняем параметры рост и вес и даем рекомендации. В
          ряде случаев, если товар представлен в нашем шоу-руме мы можем
          прислать дополнительные фотографии товара на наших сотрудниках, схожих
          по параметрам на вас.
        </p> */}
      </div>
    </>
  );
};

export default Advice;
