import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, NavLink, useHistory } from "react-router-dom";
import store from "../../MobX";

const MobileMenuOpenCategory = ({
  setMobileMenu,
  setNoScroll,
  menu,
  setMenu,
  setPrevMenu,
}) => {
  const { isCatalog = true } = menu;
  return (
    <div className="mob-menu-open-category">
      <p className="mob-menu-open-category__title">{menu.subMenuTitle.name}</p>
      <div className="mob-menu-open-category__list">
        {menu.listLinks.map((item) => {
          if (item.childs) {
            return (
              <div
                className="mob-menu-open-category__item"
                onClick={() => {
                  // setListLinks(item.childs);
                  // setSubMenuTitle({ name: item.name, slug: item.slug });
                  setPrevMenu({ ...menu });
                  setMenu({
                    ...menu,
                    listLinks: item.childs,
                    subMenuTitle: { name: item.name, slug: item.slug },
                  });
                }}
              >
                <a>{item.name}</a>
                <i className="i-down" />
              </div>
            );
          } else {
            return (
              <div className="mob-menu-open-category__item">
                <NavLink
                  to={`${isCatalog ? "/catalog" : ""}/${menu.mainSlug}${
                    isCatalog ? "/" + menu.subMenuTitle.slug : ""
                  }/${item.slug}`}
                  onClick={() => {
                    store.backInMobMenu = null;
                    setMobileMenu(false);
                    setNoScroll(false);
                  }}
                >
                  {item.name}
                </NavLink>
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};

const defaultValue = {
  linkIsActive: false,
  listLinks: [],
  subMenuTitle: null,
  mainSlug: "",
};

const MobileMenu = observer(
  ({ setNoScroll, noScroll, categ, setMobileMenu, brandsList, isHollyday }) => {
    const [menu, setMenu] = useState(defaultValue);
    const [prevMenu, setPrevMenu] = useState(defaultValue);
    const history = useHistory();

    useEffect(() => {
      if (store.backInMobMenu === "back") {
        setMenu({ ...prevMenu });
        if (prevMenu.linkIsActive) {
          setPrevMenu(defaultValue);
          store.backInMobMenu = "open";
        } else {
          store.backInMobMenu = null;
        }
      }
    }, [store.backInMobMenu]);

    return (
      <div className={`mobile-menu ${isHollyday ? "mt-red" : ""}`}>
        <div className="container">
          {menu.linkIsActive ? (
            <MobileMenuOpenCategory
              setMobileMenu={setMobileMenu}
              setNoScroll={setNoScroll}
              menu={menu}
              setMenu={setMenu}
              setPrevMenu={setPrevMenu}
            />
          ) : (
            <>
              <form
                className="mobile-menu__form"
                onSubmit={(e) => {
                  e.preventDefault();

                  history.push(
                    "/search?search=" +
                      e.currentTarget.querySelector(".mobile-menu__input").value
                  );
                  setMobileMenu(false);
                  setNoScroll(false);
                }}
              >
                <input className="mobile-menu__input" placeholder="Поиск..." />
                <button type="submit" className="mobile-menu__form-btn">
                  <i className="i-search" />
                </button>
              </form>
              <div className="mobile-menu__list">
                {categ.map((title) => {
                  return (
                    <div
                      className="mobile-menu__list-item"
                      onClick={() => {
                        // setLinkIsActive(true);
                        // setListLinks(title.childs);
                        // setSubMenuTitle({ name: title.name, slug: title.slug });
                        // setMainSlug(title.slug);
                        setMenu({
                          ...menu,
                          linkIsActive: true,
                          listLinks: title.childs,
                          subMenuTitle: {
                            name: title.name,
                            slug: title.slug,
                          },

                          mainSlug: title.slug,
                        });
                        store.backInMobMenu = "open";
                      }}
                    >
                      <span className="mobile-menu__list-link">
                        {title.name}
                      </span>
                      <i className="i-down" />
                    </div>
                  );
                })}
                {brandsList.length > 0 && (
                  <div
                    className="mobile-menu__list-item"
                    onClick={() => {
                      // setLinkIsActive(true);
                      // setListLinks(title.childs);
                      // setSubMenuTitle({ name: title.name, slug: title.slug });
                      // setMainSlug(title.slug);
                      setMenu({
                        ...menu,
                        linkIsActive: true,
                        listLinks: brandsList,
                        subMenuTitle: {
                          name: "Бренды",
                          slug: "brand",
                        },
                        isCatalog: false,
                        mainSlug: "brand",
                      });
                      store.backInMobMenu = "open";
                    }}
                  >
                    <span className="mobile-menu__list-link">Бренды</span>
                    <i className="i-down" />
                  </div>
                )}
                <NavLink
                  className="mobile-menu__list-item"
                  to="/catalog/podarochnye-karty?filter=pol%3D&page=1"
                  onClick={() => {
                    store.backInMobMenu = null;
                    setMobileMenu(false);
                    setNoScroll(false);
                  }}
                >
                  <span className="mobile-menu__list-link">
                    Подарочные карты
                  </span>
                  <i className="i-down" />
                </NavLink>
                <div
                  className="mobile-menu__list-item"
                  onClick={() => {
                    setMobileMenu(false);
                    setNoScroll(false);
                  }}
                >
                  <NavLink
                    to="/sale"
                    className="mobile-menu__list-link special"
                  >
                    Акции
                  </NavLink>
                </div>
              </div>
              {store.userData !== null ? (
                <NavLink
                  to="/profile"
                  className="btn btn_primary mobile-menu__btn"
                  onClick={() => {
                    setMobileMenu(false);
                    setNoScroll(false);
                  }}
                >
                  Личный кабинет
                </NavLink>
              ) : (
                <a
                  className="btn btn_primary mobile-menu__btn"
                  onClick={() => {
                    store.showModal = true;
                    store.isModalType = { auth: true };
                  }}
                >
                  Личный кабинет
                </a>
              )}
              <a href="tel:+74995530717" className="mobile-menu__phone">
                +7 499 553-07-17
              </a>
              <div className="mobile-menu__help">
                <div className="mobile-menu__help-item">
                  <NavLink
                    to="/service/pay"
                    className="mobile-menu__help-link special"
                    onClick={() => {
                      setMobileMenu(false);
                      setNoScroll(false);
                    }}
                  >
                    Оплата и Доставка
                  </NavLink>
                </div>
                <div className="mobile-menu__help-item">
                  <NavLink
                    to="/service/warranty"
                    className="mobile-menu__help-link"
                    onClick={() => {
                      setMobileMenu(false);
                      setNoScroll(false);
                    }}
                  >
                    Гарантии и Возврат
                  </NavLink>
                </div>
                <div className="mobile-menu__help-item">
                  <NavLink
                    to="/service/bonuse"
                    className="mobile-menu__help-link"
                    onClick={() => {
                      setMobileMenu(false);
                      setNoScroll(false);
                    }}
                  >
                    Бонусы
                  </NavLink>
                </div>
                <div className="mobile-menu__help-item">
                  <NavLink
                    to="/service/shop"
                    className="mobile-menu__help-link"
                    onClick={() => {
                      setMobileMenu(false);
                      setNoScroll(false);
                    }}
                  >
                    Магазин
                  </NavLink>
                </div>
                <div className="mobile-menu__help-item">
                  <a href="/blog" className="mobile-menu__help-link">
                    Блог
                  </a>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
);

export default MobileMenu;
