import { makeAutoObservable, autorun, reaction, configure } from "mobx";
import localStorage from "mobx-localstorage";
import api from "./jsCommon/api";
import { INITIAL_STARS, nameBonusToProdStatus } from "./jsCommon/constants";

const prodInCartPrepaid = localStorage.get("prodInCart") ?? {};
const body = document.querySelector("body");

for (const key in prodInCartPrepaid) {
  const prod = prodInCartPrepaid[key];
  let change = false;

  if (
    !(
      prod &&
      !prod.putinarchive &&
      ((prod.gruppaPoCvetu &&
        prod?.gruppaPoCvetu?.izobrazheniya.length &&
        prod?.gruppaPoCvetu.izobrazheniya[0]?.formats?.medium?.url) ||
        prod.vneshnieIzobrazheniya !== undefined) &&
      prod.allwarehousesavailablecorrected > 0 &&
      !prod.productdisabled
    )
  ) {
    delete prodInCartPrepaid[key];
    change = true;
  }

  if (change) {
    localStorage.set("prodInCart", prodInCartPrepaid);
  }
}

class Store {
  constructor() {
    makeAutoObservable(this);
  }
  categ = [];
  dataCatalog = {};
  slugToNameCatalog = {};
  slugToNameCatalogFetch = {};
  nameToSlugCatalog = {};
  slugToDataSort = {};
  slugToHarFilter = {};
  slugToPath = {};
  linkToParent = {};
  slugToSEO = {};
  slugCatToLanding = {};
  slugToLanding = {};
  nameToCatForSale = {};

  categoryAdvice = {};

  idToBreadCrumb = {};

  activeProd = {};

  brands = null;
  brandsNameToSlug = null;
  slugBrandToLanding = {};
  brandsForProds = null;

  pins = {};

  catalogIsLoad = false;
  isModalType = {};
  modalData = {};
  showModal = false;
  showCartModal = false;
  isOpenDrop = false;
  funcClickOnFakeOverlay = () => {};

  prodInCart = localStorage.get("prodInCart") ?? {};
  linkToCart = "";
  seeProds = [];
  seeProdsData = [];
  sumInCart = 0;
  sumWithoutSale = 0;
  saleValue = 0;
  bonusValue = 0;
  isOnlyGiftInCart = false;

  mainSEO = {};
  serviceSEO = {};
  serviceSort = {};

  backInMobMenu = null;

  settingsAddJSCloudPay = false;

  userData =
    process.env.REACT_APP_TYPE === "local"
      ? {
          bonus: 10836,
          temporary: [
            {
              part: false,
              _id: "61f89b2832fd79e44f1838c1",
              client: "blackblazer@mail.ru",
              typeCalc: "plus",
              type: "temporary",
              temporary: {
                dateBurn: "2022-09-03T02:30:00.228Z",
                burn: false,
              },
              bonus: 5000,
              balance: 5000,
              active: true,
              dateCreate: "2022-02-01T02:30:00.228Z",
              __v: 0,
            },
            {
              part: false,
              _id: "61f89b2832fd79e44f1838c1",
              client: "blackblazer@mail.ru",
              typeCalc: "plus",
              type: "temporary",
              temporary: {
                dateBurn: "2022-09-20T02:30:00.228Z",
                burn: false,
              },
              bonus: 5000,
              balance: 5000,
              active: true,
              dateCreate: "2022-02-01T02:30:00.228Z",
              __v: 0,
            },
          ],
          email: "blackblazer@mail.ru",
          otchestvo: "",
          imya: "Мелащенко Павел",
          familiya: "",
          telefon: "+79255046798",
          status: "Профи",
          data: {
            cDate: "2018-10-24 19:05:01",
            visible: 1,
          },
          id: 22422,
          __v: 1,
          createdAt: "2021-09-28T10:02:53.094Z",
          updatedAt: "2022-07-29T01:07:48.934Z",
          fio: "Мелащенко Павел",
          gorod: "",
          lastseen: [],
          dataPonizheniyaStatusa: "2022-12-02T10:09:00.000Z",
          dataUstanovkiStatusa: "2022-02-01T02:30:00.161Z",
          avBill: 53922.291666666664,
          countCompleteOrder: 12,
          dataPosledneyPokupki: "2022-01-30T11:12:23.936Z",
          dataRuchnogoIzmeneniyaStatusa: "2021-01-30T11:12:23.936Z",
          summaPokupok: 647067.5,
          uid: "8f4a1fca3c",
          frequency: 3,
          monetary: 2,
          recency: 4,
        }
      : null;

  windowWidth = window.innerWidth;
  isMobile = this.windowWidth < 760;

  checkWidth = {
    1024: this.windowWidth < 1024,
    1000: this.windowWidth < 1000,
    800: this.windowWidth < 800,
    950: this.windowWidth < 950,
  };

  getPinText = (code) => {
    if (code) {
      return this.pins[code];
    } else {
      return undefined;
    }
  };

  /**
   *
   * @type {number[]}
   */
  stars = [];
}

// decorate(Store, {
//   categories: observable,
//   posts: observable,
//   products: observable,
// });

const store = new Store();

const getCartData = () => {
  let sumToCalc = 0;
  let saleValueToCalc = 0;
  let bonusValueToCalc = 0;
  let sumWithoutSaleToCalc = 0;
  let isOnlyGiftInCartTemp = false;
  const valuesInCart = Object.values(store.prodInCart);

  if (
    valuesInCart.length === 1 &&
    valuesInCart[0]?.cardname === "Подарочная страница"
  ) {
    isOnlyGiftInCartTemp = true;
  }

  valuesInCart.forEach((prod) => {
    sumToCalc += prod.priceretail * prod.countInCart;
    saleValueToCalc +=
      (prod.priceretail - prod.pricesaleretail) * prod.countInCart;

    if (store.userData !== null) {
      bonusValueToCalc += Math.round(
        prod.pricesaleretail *
          (prod[nameBonusToProdStatus[store.userData.status]] / 100) *
          prod.countInCart
      );
    }
    if (
      prod.pricesaleretail === prod.priceretail &&
      prod.cardname !== "Подарочная страница"
    )
      sumWithoutSaleToCalc += prod.pricesaleretail * prod.countInCart;
  });
  store.sumInCart = sumToCalc;
  store.sumWithoutSale = sumWithoutSaleToCalc;
  store.saleValue = saleValueToCalc;
  store.bonusValue = bonusValueToCalc;
  store.isOnlyGiftInCart = isOnlyGiftInCartTemp;
};

try {
  getCartData();
} catch (error) {}

const cartBonusIfUserChange = reaction(
  () => store.userData,
  () => {
    getCartData();
  }
);

const checkChangeProductPriceInCart = async () => {
  const articles = Object.keys(store.prodInCart);
  if (!articles.length) return;
  const prodInCartNew = {};

  await api
    .getProductArticle({ articles })
    .then((prods) => {
      prods.forEach((prod) => {
        delete prod.tovar;
        const article = prod.productcode;

        if (
          store.prodInCart[article].gruppaPoCvetu.slug ===
          "podarochnaya-stranica"
        ) {
          prod.priceretail = store.prodInCart[article].priceretail;
          prod.pricesaleretail = store.prodInCart[article].priceretail;
        }

        prodInCartNew[article] = {
          ...prod,
          gruppaPoCvetu: store.prodInCart[article].gruppaPoCvetu,
          countInCart: store.prodInCart[article].countInCart,
          text: store.prodInCart[article].text,
          imageURI: store.prodInCart[article].imageURI,
        };
      });
    })
    .catch((err) => {
      console.error("err :>> ", err);
    });

  store.prodInCart = prodInCartNew;
  localStorage.set("prodInCart", prodInCartNew);
  getCartData();
};

checkChangeProductPriceInCart();

setInterval(() => {
  checkChangeProductPriceInCart();
}, 1000 * 60 * 5);

const cartLocaleStore = reaction(
  () =>
    Object.keys(store.prodInCart).map(
      (prodKey) => prodKey + store.prodInCart[prodKey].countInCart
    ),
  () => {
    if (
      !Object.keys(store.prodInCart).includes(undefined) &&
      !Object.keys(store.prodInCart).includes("undefined")
    ) {
      localStorage.set("prodInCart", store.prodInCart);
      store.linkToCart = "";
    }
    // let sumToCalc = 0;
    // let saleValueToCalc = 0;
    // let bonusValueToCalc = 0;

    // Object.values(store.prodInCart).forEach((prod) => {
    //   sumToCalc += prod.priceretail * prod.countInCart;
    //   saleValueToCalc +=
    //     (prod.priceretail - prod.pricesaleretail) * prod.countInCart;
    //   if (
    //     store.userData !== null &&
    //     prod[nameBonusToProdStatus[store.userData.status]]
    //   ) {
    //     bonusValueToCalc += Math.round(
    //       prod.pricesaleretail *
    //         (prod[nameBonusToProdStatus[store.userData.status]] / 100) *
    //         prod.countInCart
    //     );
    //   }
    // });
    // console.log("bonusValueToCalc :>> ", bonusValueToCalc);
    // store.sumInCart = sumToCalc;
    // store.saleValue = saleValueToCalc;
    // store.bonusValue = bonusValueToCalc;
  }
);
const cartInLocaleStore = reaction(
  () =>
    localStorage.get("prodInCart")
      ? Object.keys(localStorage.get("prodInCart")).map(
          (prodKey) =>
            prodKey + localStorage.get("prodInCart")[prodKey].countInCart
        )
      : [],
  () => {
    let sumToCalc = 0;
    let saleValueToCalc = 0;
    let bonusValueToCalc = 0;
    let sumWithoutSaleToCalc = 0;
    let isOnlyGiftInCartTemp = false;
    const valuesInCart = Object.values(store.prodInCart);

    if (
      valuesInCart.length === 1 &&
      valuesInCart[0]?.cardname === "Подарочная страница"
    ) {
      isOnlyGiftInCartTemp = true;
    }

    valuesInCart.forEach((prod) => {
      sumToCalc += prod.priceretail * prod.countInCart;
      saleValueToCalc +=
        (prod.priceretail - prod.pricesaleretail) * prod.countInCart;
      if (
        store.userData !== null &&
        prod[nameBonusToProdStatus[store.userData.status]]
      ) {
        bonusValueToCalc += Math.round(
          prod.pricesaleretail *
            (prod[nameBonusToProdStatus[store.userData.status]] / 100) *
            prod.countInCart
        );
      }
      if (
        prod.priceretail === prod.pricesaleretail &&
        prod.cardname !== "Подарочная страница"
      )
        sumWithoutSaleToCalc += prod.pricesaleretail * prod.countInCart;
    });

    store.sumInCart = sumToCalc;
    store.saleValue = saleValueToCalc;
    store.bonusValue = bonusValueToCalc;
    store.sumWithoutSale = sumWithoutSaleToCalc;
    store.isOnlyGiftInCart = isOnlyGiftInCartTemp;
    const newCart = localStorage.get("prodInCart");

    if (
      Object.keys(newCart)
        .map((prodKey) => prodKey + newCart[prodKey].countInCart)
        .join("") !==
      Object.keys(store.prodInCart)
        .map((prodKey) => prodKey + store.prodInCart[prodKey].countInCart)
        .join("")
    )
      store.prodInCart = localStorage.get("prodInCart");
  }
);

const seeProdsLocaleStore = reaction(
  () => store.activeProd.slug,
  async () => {
    if (store.activeProd.slug !== undefined) {
      const index = store.seeProds.indexOf(store.activeProd.slug);

      const newSee = [...store.seeProds];
      if (index !== -1) {
        newSee.splice(index, 1);
      } else if (newSee.length >= 16) {
        newSee.pop();
      }
      newSee.unshift(store.activeProd.slug);
      store.seeProds = [...newSee];
      localStorage.set("seeProds", [...newSee]);
      // try {
      //   if (store.userData !== null) {
      //     api
      //       .putUser({ lastseen: [...newSee] })
      //       .then((result) => {})
      //       .catch((err) => {});
      //   }
      // } catch (err) {
      //   console.log("err :>> ", err);
      // }
    }
  }
);

window.addEventListener("resize", () => {
  store.windowWidth = window.innerWidth;
});

reaction(
  () => store.windowWidth,
  () => {
    if (
      (store.windowWidth < 760 && !store.isMobile) ||
      (store.windowWidth > 760 && store.isMobile)
    )
      store.isMobile = store.windowWidth < 760;

    Object.keys(store.checkWidth).forEach((width) =>
      store.windowWidth < +width !== store.checkWidth[width]
        ? (store.checkWidth[width] = store.windowWidth < +width)
        : ""
    );
  }
);
reaction(
  () => store.showModal,
  () => {
    if (body) {
      if (store.showModal) body.classList.add("no-scroll");
      else body.classList.remove("no-scroll");
    }
  }
);
reaction(
  () => store.seeProds,
  async () => {
    if (store.seeProds.length) {
      api
        .getProductsBySlugs(store.seeProds)
        .then((lastSeenProds) => {
          if (Array.isArray(lastSeenProds)) {
            const prodDataObj = {};
            lastSeenProds.forEach((p) => (prodDataObj[p.slug] = p));
            const arrSee = [];
            store.seeProds.forEach((p) =>
              prodDataObj[p] !== undefined ? arrSee.push(prodDataObj[p]) : ""
            );
            store.seeProdsData = arrSee;
          }
        })
        .catch((err) => {
          console.error("err :>> ", err);
        });
    }
  }
);

store.seeProds = localStorage.get("seeProds") ?? [];
// const changeCompLocalStorage = autorun(() => {
//   if (Object.keys(store.compareProds).length === 0) {
//     window.localStorage.removeItem("compare");
//   } else {
//     window.localStorage.setItem("compare", JSON.stringify(store.compareProds));
//   }
// });

// const postFilter = autorun(() => {
//   if (store.activeSect) {
//     api
//       .postPostSect({ sections: store.activeSect, start: 0 })
//       .then((postsFilt) => {
//         store.posts = postsFilt;
//       })
//       .catch((err) => {});
//   } else {
//     api
//       .getPosts()
//       .then((postsAll) => {
//         store.posts = postsAll;
//       })
//       .catch((err) => {});
//   }
// });

// const postPafinat = autorun(() => {

//     api
//       .postPostSect({ sections: store.activeSect, start: store.postsPage * 5 })
//       .then((postsFilt) => {
//         store.posts = postsFilt;
//       })
//       .catch((err) => {
//       });

// });

export default store;
