import React, { useEffect, useState } from "react";
import { returnSizeTableData } from "../../../funcs";

import store from "../../../MobX";

const Size = ({}) => {
  const { modalData } = store;

  const brand = modalData.brand.toUpperCase();
  const tableData = returnSizeTableData(
    brand,
    modalData.pol,
    modalData.sizeType
  );
  const headerHTML = tableData
    ? tableData.headers?.map((str) => <th>{str}</th>)
    : null;

  const linesHTML = tableData
    ? tableData.lines.map((line) => (
        <tr>
          {line.map((str) => (
            <td>
              <span className="table__str-title">{str}</span>
            </td>
          ))}
        </tr>
      ))
    : null;

  return (
    <>
      <div className="modal-window__size">
        <table className="table">
          <thead className="thead">
            <tr>
              {headerHTML}
              {/* <th></th>
              <th>XS</th>
              <th>S</th>
              <th>M</th>
              <th>L</th>
              <th>XL</th>
              <th>2XL</th> */}
            </tr>
          </thead>
          <tbody className="tbody">{linesHTML}</tbody>
        </table>
        {/* <p>
          Таблицы размеров взяты с официальных сайтов или каталогов
          производителя. Продавец не несет ответсвенности за достоверность
          представленной информации. Во время согласования заказа мы
          дополнительно уточняем параметры рост и вес и даем рекомендации. В
          ряде случаев, если товар представлен в нашем шоу-руме мы можем
          прислать дополнительные фотографии товара на наших сотрудниках, схожих
          по параметрам на вас. Таблицы размеров взяты с официальных сайтов или
          каталогов производителя. Продавец не несет ответсвенности за
          достоверность представленной информации. Во время согласования заказа
          мы дополнительно уточняем параметры рост и вес и даем рекомендации. В
          ряде случаев, если товар представлен в нашем шоу-руме мы можем
          прислать дополнительные фотографии товара на наших сотрудниках, схожих
          по параметрам на вас.
        </p> */}
      </div>
    </>
  );
};

export default Size;
