import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import "./styles/index.scss";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import "./fonts.css";
import "./i-gift.css";
import sbjs from "sourcebuster";

try {
  sbjs.init({
    organics: [
      {
        host: "yandex.ru",
        param: "ysclid",
        display: "yandex",
      },
    ],
  });
} catch (err) {
  console.error("err :>> ", err);
}

ReactDOM.render(
  <Router>
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </Router>,
  document.getElementById("root")
);

reportWebVitals();
