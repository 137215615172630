import React, { useEffect, useState } from "react";

//${mobileMenu ? "deactive" : ""}
const RedLine = ({ text, isMobile, mobileMenu }) => {
  //   if (typeof text == "string") text = text.toUpperCase();
  // const isMobileS = window.innerWidth <= 320;

  return (
    <div className={`red-line `}>
      <p style={{ textTransform: "uppercase" }}>
        ДРУЗЬЯ! шоу-рум на Октябрьская 69 закрыт! {isMobile ? <br /> : ""}{" "}
        Онлайн заказы обрабатываются в обычном режиме!
        {/*{isMobile ? <br /> : ""} */}
        {/*{isMobile ? "" : "С ПРАЗДНИКОМ!"}*/}
        {/* {isMobile ? <br /> : ""} ВЕРНЁМСЯ К
        ВАМ 03.01!{!isMobile ? " С НОВЫМ ГОДОМ!" : ""} */}
      </p>
    </div>
  );
};

export default RedLine;
