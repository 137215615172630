import React, { useEffect, useState } from "react";
import api from "../../../jsCommon/api";
import { useForm } from "react-hook-form";
import store from "../../../MobX";
import Inputmask from "inputmask";
import { observer } from "mobx-react-lite";

const Auth = observer(() => {
  const [authorized, setAuthorized] = useState(
    store.isModalType.type !== "registr"
  );
  const [restorePass, setRestorePass] = useState(false);
  const [buttonText, setButtonText] = useState(
    authorized
      ? restorePass
        ? "Прислать на почту"
        : "Войти"
      : "Зарегистрироваться"
  );
  const [accountCreated, setAccountCreated] = useState(false);
  const [success, setSuccess] = useState(false);
  const [fail, setFail] = useState(false);
  // const [formData, setFormData] = useState({ email: "", password: "" });
  const {
    register,
    handleSubmit,
    formState: { errors },
    getValues,
  } = useForm();

  useEffect(() => {
    if (success)
      setTimeout(() => {
        setSuccess(false);
      }, 2500);
  }, [success]);
  useEffect(() => {
    if (fail)
      setTimeout(() => {
        setFail(false);
      }, 2500);
  }, [fail]);

  useEffect(() => {
    if (store.userData) {
      store.showModal = false;
      store.isModalType = {};
    }
  }, [store.userData]);

  console.log(errors);

  useEffect(() => {
    setButtonText(
      authorized
        ? restorePass
          ? "Прислать на почту"
          : "Войти"
        : "Зарегистрироваться"
    );
  }, [authorized, restorePass]);

  const onSubmit = (data, e) => {
    e.preventDefault();
    e.stopPropagation();
    if (data.email.includes("@")) data.email = data.email.toLowerCase();
    else {
      data.tel = data.email.replace(/\D/g, "");
      delete data.email;
      if (data.tel.startsWith("8")) {
        data.tel = "+7" + data.tel.substring(1, data.tel.length);
      } else if (data.tel.startsWith("7")) {
        data.tel = "+" + data.tel;
      }
    }

    if (authorized) {
      if (!restorePass)
        api
          .login(data)
          .then((result) => {
            if (!store.isModalType.notToProfile)
              window.location.assign("/profile");
            else
              api
                .getUser()
                .then((result) => {
                  store.userData = result;
                  if (
                    store.userData.lastseen &&
                    store.userData.lastseen.length
                  ) {
                    store.seeProds = store.userData.lastseen;
                  }
                  store.showModal = false;
                  store.isModalType = {};
                })
                .catch((err) => {
                  store.userData = null;
                  console.error("err :>> ", err);
                });
          })
          .catch((err) => {
            console.error("err :>> ", err);
            setFail(true);
            if (err.message) {
              setButtonText(err.message);
              setTimeout(() => {
                setButtonText(
                  authorized
                    ? restorePass
                      ? "Прислать на почту"
                      : "Войти"
                    : "Зарегистрироваться"
                );
              }, 2000);
            }
            e.target.classList.add("error");
          });
      else
        api
          .restorePass({ email: data.email })
          .then((result) => {
            setSuccess(true);
          })
          .catch((err) => {
            setFail(true);
            e.target.classList.add("error");
          });
    } else {
      api
        .registration({ email: data.email })
        .then((result) => {
          setAccountCreated(true);
          setSuccess(true);

          // if (!store.isModalType.notToProfile)
          //   window.location.assign("/profile");
          // else
          //   api
          //     .getUser()
          //     .then((result) => {
          //       console.log("result :>> ", result);
          //       store.userData = result;
          //       if (store.userData.lastseen && store.userData.lastseen.length) {
          //         store.seeProds = store.userData.lastseen;
          //       }
          //     })
          //     .catch((err) => {
          //       store.userData = null;
          //       console.log("err :>> ", err);
          //     });
        })
        .catch((err) => {
          console.error("err :>> ", err);
          setFail(true);
          if (err.message) {
            setButtonText(err.message);
            setTimeout(() => {
              setButtonText(
                authorized
                  ? restorePass
                    ? "Прислать на почту"
                    : "Войти"
                  : "Зарегистрироваться"
              );
            }, 2000);
          }
          e.target.classList.add("error");
        });
    }
  };

  return (
    <>
      <div className="btn__switch">
        <button
          className={`${
            authorized ? "btn_primary" : "btn_secondary"
          } btn authorization-modal__btn`}
          onClick={() => {
            setAuthorized(true);
          }}
        >
          Вход
        </button>
        <button
          className={`${
            authorized ? "btn_secondary" : "btn_primary"
          } btn authorization-modal__btn`}
          onClick={() => {
            setAuthorized(false);
          }}
        >
          Регистрация
        </button>
      </div>
      <form
        className="modal-window__form mt20"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="modal-window__form-email">
          <label
            className={`label-wrp ${
              accountCreated && !authorized && !authorized ? "success" : ""
            }`}
          >
            E-mail{" "}
            <input
              type="text"
              className={`${
                accountCreated && !authorized && !authorized ? "success" : ""
              }`}
              // value={formData.email}
              // onChange={(e) => {
              //   formData.email = e.target.value;
              //   setFormData({ ...formData });
              // }}
              //pattern: /^\S+@\S+$/i,
              {...register("email", {
                required: true,
                validate: (value) => {
                  if (authorized && !restorePass && !value.includes("@")) {
                    const telOnly = value.replace(/\D/g, "");
                    if (telOnly.length === 11) {
                      return true;
                    }
                  }
                  if (value.includes("@")) {
                    return /^\S+@\S+$/i.test(value);
                  }
                  return false;
                },
                // onBlur: (e) => {
                //   const email = getValues("email");
                //   if (!errors.email) {
                //     if (
                //       !email.includes("@") &&
                //       (email.length === 11 || email.length === 12)
                //     ) {
                //       const tel = new Inputmask({
                //         mask: "+7 (999) 999 99 99",
                //         showMaskOnHover: false,
                //         // isComplete: function()
                //       });
                //
                //       tel.mask(e.target);
                //     } else {
                //       Inputmask.remove(e.target);
                //     }
                //   }
                // },
              })}
            />
          </label>
        </div>
        {authorized && !restorePass ? (
          <div className="modal-window__form-password">
            <label className="label-wrp mt20">
              Пароль
              <input
                type="password"
                // onChange={(e) => {
                //   formData.password = e.target.value;
                //   setFormData({ ...formData });
                // }}
                {...register("password", {
                  required: true,
                  minLength: 5,
                  maxLength: 40,
                })}
              />
            </label>
          </div>
        ) : (
          <p className="authorization-modal__activation-info">
            {accountCreated && !authorized
              ? "Учётная запись успешно создана. Для активации, пожалуйста, проверьте электронную почту."
              : !restorePass
              ? "Мы направим на указанный E-mail письмо для активации личного кабинета."
              : "Мы направим на указанный E-mail письмо с новым паролем"}
          </p>
        )}
        {authorized && (
          <a
            className="authorization-modal__forget"
            onClick={() => setRestorePass((b) => !b)}
          >
            {restorePass ? "Вспомнили пароль?" : "Забыли пароль?"}
          </a>
        )}
        <button
          className={`btn btn_primary authorization-modal__form-btn ${
            success ? "success" : ""
          } ${fail ? "fail" : ""}`}
          // onClick={(e) => {
          //   e.preventDefault();
          //   e.stopPropagation();

          // }}
          id="buttonAuth"
          type="submit"
        >
          {buttonText}
        </button>
      </form>
    </>
  );
});

export default Auth;
