const GIFT_DATA_PRODS = {
  0: {
    izobrazheniya: [
      {
        _id: "639978a9ea60f216f68d37d2",
        name: "0.png",
        alt: "",
        width: "1908",
        height: "1908",
        hash: "0-8f99a685844883a3.",
        ext: "png",
        mime: "image/png",
        size: 717581,
        url: "/pictures/0-8f99a685844883a3.png",
        provider: "local",
        infoCMS: "624e9d2cc47619ec4f988f7d",
        formats: {
          large: {
            name: "large-0-8f99a685844883a3.",
            width: "1001",
            height: "1001",
            size: 564306,
            url: "/pictures/large-0-8f99a685844883a3.png",
          },
          medium: {
            name: "medium-0-8f99a685844883a3.",
            width: "750",
            height: "750",
            size: 332412,
            url: "/pictures/medium-0-8f99a685844883a3.png",
          },
          small: {
            name: "small-0-8f99a685844883a3.",
            width: "500",
            height: "500",
            size: 156260,
            url: "/pictures/small-0-8f99a685844883a3.png",
          },
          thumbnail: {
            name: "thumbnail-0-8f99a685844883a3.",
            width: "236",
            height: "236",
            size: 37783,
            url: "/pictures/thumbnail-0-8f99a685844883a3.png",
          },
        },
        whereUsed: [],
        dateCreate: "2022-12-14T07:18:01.752Z",
        updatedAt: "2022-12-14T07:18:01.752Z",
        __v: 0,
      },
    ],
  },
  10000: {
    priceretail: 10000,
    pricesaleretail: 10000,
    productcode: "giftpage10",
    _id: "63876f34b7679c05aa743004",
    partnumber: "giftpage10",
    itemdescription: "podarochnaya_stranica_10000",
    mcid: "4854f4b4-461c-11ed-0a80-032c00082f46",
    gruppaPoCvetu: {
      cena: 10000,
      cenaSoSkidkoy: 10000,
      itemdesc: "podarochnaya_stranica_10000",
      skuProizvoditelya: ["giftpage10"],
      skuVnutrenniy: ["giftpage10"],
      slug: "podarochnaya-stranica-10000",
      tovar: "63876f34b7679c05aa743021",
      _id: "63876f34b7679c05aa74300f",
      izobrazheniya: [
        {
          _id: "63877127b7679c05aa746ba8",
          name: "gift_card_10.png",
          alt: "",
          width: "1908",
          height: "1908",
          hash: "gift_card_10-a604933181217421.",
          ext: "png",
          mime: "image/png",
          size: 486283,
          url: "/pictures/gift_card_10-a604933181217421.png",
          provider: "local",
          infoCMS: "624e9d2cc47619ec4f988f7d",
          formats: {
            large: {
              name: "large-gift_card_10-a604933181217421.",
              width: "1001",
              height: "1001",
              size: 461943,
              url: "/pictures/large-gift_card_10-a604933181217421.png",
            },
            medium: {
              name: "medium-gift_card_10-a604933181217421.",
              width: "750",
              height: "750",
              size: 271333,
              url: "/pictures/medium-gift_card_10-a604933181217421.png",
            },
            small: {
              name: "small-gift_card_10-a604933181217421.",
              width: "500",
              height: "500",
              size: 125270,
              url: "/pictures/small-gift_card_10-a604933181217421.png",
            },
            thumbnail: {
              name: "thumbnail-gift_card_10-a604933181217421.",
              width: "236",
              height: "236",
              size: 34924,
              url: "/pictures/thumbnail-gift_card_10-a604933181217421.png",
            },
          },
          whereUsed: [
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "zapolnenieKontenta",
              slugCol: "zapolnenie_kontenta",
              id: "638b77956182764a4cc62195",
            },
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "tovaryPoCvetam",
              slugCol: "tovary_po_cvetam",
              id: "63876f34b7679c05aa74300f",
            },
          ],
          dateCreate: "2022-11-30T15:05:11.848Z",
          updatedAt: "2022-12-03T16:21:42.035Z",
          __v: 0,
        },
      ],
    },
  },
  25000: {
    priceretail: 25000,
    pricesaleretail: 25000,
    productcode: "giftpage25",
    _id: "63876fa8b7679c05aa7431c6",
    partnumber: "giftpage25",
    itemdescription: "podarochnaya_stranica_25000",
    mcid: "75648bc4-461c-11ed-0a80-0b2a00089608",
    gruppaPoCvetu: {
      cena: 25000,
      cenaSoSkidkoy: 25000,
      itemdesc: "podarochnaya_stranica_25000",
      skuProizvoditelya: ["giftpage25"],
      skuVnutrenniy: ["giftpage25"],
      slug: "podarochnaya-stranica-25000",
      tovar: "63876f34b7679c05aa743021",
      _id: "63876fa8b7679c05aa7431d2",
      izobrazheniya: [
        {
          _id: "638773a8b7679c05aa7477c0",
          name: "gift_card_25.png",
          alt: "",
          width: "1908",
          height: "1908",
          hash: "gift_card_25-788d64c6a2771339.",
          ext: "png",
          mime: "image/png",
          size: 639658,
          url: "/pictures/gift_card_25-788d64c6a2771339.png",
          provider: "local",
          infoCMS: "624e9d2cc47619ec4f988f7d",
          formats: {
            large: {
              name: "large-gift_card_25-788d64c6a2771339.",
              width: "1001",
              height: "1001",
              size: 507342,
              url: "/pictures/large-gift_card_25-788d64c6a2771339.png",
            },
            medium: {
              name: "medium-gift_card_25-788d64c6a2771339.",
              width: "750",
              height: "750",
              size: 297581,
              url: "/pictures/medium-gift_card_25-788d64c6a2771339.png",
            },
            small: {
              name: "small-gift_card_25-788d64c6a2771339.",
              width: "500",
              height: "500",
              size: 137031,
              url: "/pictures/small-gift_card_25-788d64c6a2771339.png",
            },
            thumbnail: {
              name: "thumbnail-gift_card_25-788d64c6a2771339.",
              width: "236",
              height: "236",
              size: 35229,
              url: "/pictures/thumbnail-gift_card_25-788d64c6a2771339.png",
            },
          },
          whereUsed: [
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "tovaryPoCvetam",
              slugCol: "tovary_po_cvetam",
              id: "63876fa8b7679c05aa7431d2",
            },
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "zapolnenieKontenta",
              slugCol: "zapolnenie_kontenta",
              id: "638b77966182764a4cc621c7",
            },
          ],
          dateCreate: "2022-11-30T15:15:52.259Z",
          updatedAt: "2022-12-03T16:21:42.171Z",
          __v: 0,
        },
      ],
    },
  },
  50000: {
    priceretail: 50000,
    pricesaleretail: 50000,
    productcode: "giftpage50",
    _id: "63877288b7679c05aa747144",
    partnumber: "giftpage50",
    itemdescription: "podarochnaya_stranica_50000",
    mcid: "8b9616b3-461c-11ed-0a80-09240008c069",
    gruppaPoCvetu: {
      cena: 50000,
      cenaSoSkidkoy: 50000,
      itemdesc: "podarochnaya_stranica_50000",
      skuProizvoditelya: ["giftpage50"],
      skuVnutrenniy: ["giftpage50"],
      slug: "podarochnaya-stranica-50000",
      tovar: "63876f34b7679c05aa743021",
      _id: "63877288b7679c05aa747151",
      izobrazheniya: [
        {
          _id: "6387741db7679c05aa74796a",
          name: "gift_card_50.png",
          alt: "",
          width: "1908",
          height: "1908",
          hash: "gift_card_50-df5279341d6db8b6.",
          ext: "png",
          mime: "image/png",
          size: 541887,
          url: "/pictures/gift_card_50-df5279341d6db8b6.png",
          provider: "local",
          infoCMS: "624e9d2cc47619ec4f988f7d",
          formats: {
            large: {
              name: "large-gift_card_50-df5279341d6db8b6.",
              width: "1001",
              height: "1001",
              size: 446224,
              url: "/pictures/large-gift_card_50-df5279341d6db8b6.png",
            },
            medium: {
              name: "medium-gift_card_50-df5279341d6db8b6.",
              width: "750",
              height: "750",
              size: 263803,
              url: "/pictures/medium-gift_card_50-df5279341d6db8b6.png",
            },
            small: {
              name: "small-gift_card_50-df5279341d6db8b6.",
              width: "500",
              height: "500",
              size: 123502,
              url: "/pictures/small-gift_card_50-df5279341d6db8b6.png",
            },
            thumbnail: {
              name: "thumbnail-gift_card_50-df5279341d6db8b6.",
              width: "236",
              height: "236",
              size: 33082,
              url: "/pictures/thumbnail-gift_card_50-df5279341d6db8b6.png",
            },
          },
          whereUsed: [
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "tovaryPoCvetam",
              slugCol: "tovary_po_cvetam",
              id: "63877288b7679c05aa747151",
            },
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "zapolnenieKontenta",
              slugCol: "zapolnenie_kontenta",
              id: "638b77966182764a4cc621ff",
            },
          ],
          dateCreate: "2022-11-30T15:17:49.030Z",
          updatedAt: "2022-12-03T16:21:42.268Z",
          __v: 0,
        },
      ],
    },
  },
  75000: {
    priceretail: 75000,
    pricesaleretail: 75000,
    productcode: "giftpage75",
    _id: "638772eeb7679c05aa74736a",
    partnumber: "giftpage75",
    itemdescription: "podarochnaya_stranica_75000",
    mcid: "9930a5a8-461c-11ed-0a80-06a000089629",
    gruppaPoCvetu: {
      cena: 75000,
      cenaSoSkidkoy: 75000,
      itemdesc: "podarochnaya_stranica_75000",
      skuProizvoditelya: ["giftpage75"],
      skuVnutrenniy: ["giftpage75"],
      slug: "podarochnaya-stranica-75000",
      tovar: "63876f34b7679c05aa743021",
      _id: "638772efb7679c05aa747378",
      izobrazheniya: [
        {
          _id: "63877435b7679c05aa747a41",
          name: "gift_card_75.png",
          alt: "",
          width: "1908",
          height: "1908",
          hash: "gift_card_75-bc798fdf9031b8a0.",
          ext: "png",
          mime: "image/png",
          size: 422380,
          url: "/pictures/gift_card_75-bc798fdf9031b8a0.png",
          provider: "local",
          infoCMS: "624e9d2cc47619ec4f988f7d",
          formats: {
            large: {
              name: "large-gift_card_75-bc798fdf9031b8a0.",
              width: "1001",
              height: "1001",
              size: 399764,
              url: "/pictures/large-gift_card_75-bc798fdf9031b8a0.png",
            },
            medium: {
              name: "medium-gift_card_75-bc798fdf9031b8a0.",
              width: "750",
              height: "750",
              size: 246168,
              url: "/pictures/medium-gift_card_75-bc798fdf9031b8a0.png",
            },
            small: {
              name: "small-gift_card_75-bc798fdf9031b8a0.",
              width: "500",
              height: "500",
              size: 124136,
              url: "/pictures/small-gift_card_75-bc798fdf9031b8a0.png",
            },
            thumbnail: {
              name: "thumbnail-gift_card_75-bc798fdf9031b8a0.",
              width: "236",
              height: "236",
              size: 35455,
              url: "/pictures/thumbnail-gift_card_75-bc798fdf9031b8a0.png",
            },
          },
          whereUsed: [
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "tovaryPoCvetam",
              slugCol: "tovary_po_cvetam",
              id: "638772efb7679c05aa747378",
            },
          ],
          dateCreate: "2022-11-30T15:18:13.315Z",
          updatedAt: "2022-11-30T15:18:15.539Z",
          __v: 0,
        },
      ],
    },
  },
  100000: {
    priceretail: 100000,
    pricesaleretail: 100000,
    productcode: "giftpage100",
    _id: "63877338b7679c05aa7474d1",
    partnumber: "giftpage100",
    itemdescription: "podarochnaya_stranica_100000",
    mcid: "aa39ec88-461c-11ed-0a80-0d3a000941b1",
    gruppaPoCvetu: {
      cena: 100000,
      cenaSoSkidkoy: 100000,
      itemdesc: "podarochnaya_stranica_100000",
      skuProizvoditelya: ["giftpage100"],
      skuVnutrenniy: ["giftpage100"],
      slug: "podarochnaya-stranica-100000",
      tovar: "63876f34b7679c05aa743021",
      _id: "63877338b7679c05aa7474e0",
      izobrazheniya: [
        {
          _id: "63877449b7679c05aa747ad5",
          name: "gift_card_100.png",
          alt: "",
          width: "1908",
          height: "1908",
          hash: "gift_card_100-d7b8fa697b1ef029.",
          ext: "png",
          mime: "image/png",
          size: 392918,
          url: "/pictures/gift_card_100-d7b8fa697b1ef029.png",
          provider: "local",
          infoCMS: "624e9d2cc47619ec4f988f7d",
          formats: {
            large: {
              name: "large-gift_card_100-d7b8fa697b1ef029.",
              width: "1001",
              height: "1001",
              size: 383889,
              url: "/pictures/large-gift_card_100-d7b8fa697b1ef029.png",
            },
            medium: {
              name: "medium-gift_card_100-d7b8fa697b1ef029.",
              width: "750",
              height: "750",
              size: 233645,
              url: "/pictures/medium-gift_card_100-d7b8fa697b1ef029.png",
            },
            small: {
              name: "small-gift_card_100-d7b8fa697b1ef029.",
              width: "500",
              height: "500",
              size: 116821,
              url: "/pictures/small-gift_card_100-d7b8fa697b1ef029.png",
            },
            thumbnail: {
              name: "thumbnail-gift_card_100-d7b8fa697b1ef029.",
              width: "236",
              height: "236",
              size: 34863,
              url: "/pictures/thumbnail-gift_card_100-d7b8fa697b1ef029.png",
            },
          },
          whereUsed: [
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "tovaryPoCvetam",
              slugCol: "tovary_po_cvetam",
              id: "63877338b7679c05aa7474e0",
            },
            {
              cmsSlug: "main",
              type: "collections",
              nameDBCol: "zapolnenieKontenta",
              slugCol: "zapolnenie_kontenta",
              id: "638b77966182764a4cc62233",
            },
          ],
          dateCreate: "2022-11-30T15:18:33.505Z",
          updatedAt: "2022-12-03T16:21:42.385Z",
          __v: 0,
        },
      ],
    },
  },
};

export { GIFT_DATA_PRODS };
