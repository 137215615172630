import React, { useEffect, useState } from "react";
import logo from "../../img/logofoot.svg";
import { NavLink } from "react-router-dom";
import fb from "../../img/face.svg";
import inst from "../../img/inst.svg";
import vk from "../../img/vk.svg";
import map from "../../img/map-footer.jpg";
import store from "../../MobX";

const Footer = () => {
  return (
    <div className="footer">
      <div className="container">
        <div className="row footer__row">
          <div className="col col-3 col-m-4 col-s-12 footer__col footer__contact">
            <img src={logo} />
            <div className="footer__phone">
              <a href="tel:+74995530717" className="footer__phone-number white">
                +7 499 553-07-17
              </a>
              <a
                className="btn btn_primary btn_sm footer__phone-label white"
                onClick={(e) => {
                  e.preventDefault();
                  store.isModalType.callback = true;
                  store.showModal = true;
                }}
              >
                Связаться
              </a>
            </div>
            <div className="footer__social">
              {/* <a
                href="http://fb.me/motorfirst.1st"
                className=""
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={fb}
                  className="footer__social-fb"
                  alt="facebook icon"
                />
              </a> */}
              {/* <a
                href="https://instagram.com/motorfirst/"
                className=""
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={inst}
                  className="footer__social-inst"
                  alt="instagram icon"
                />
              </a> */}
              <a
                href="https://vk.com/motorfirst"
                className=""
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={vk}
                  className="footer__social-vk"
                  alt="instagram icon"
                />
              </a>
            </div>
          </div>
          <div className="col col-3 col-s-12 footer__help ">
            <h5 className="h4 w">Сервис и помощь</h5>
            <ul className="footer__help-list">
              <li className="footer__help-item">
                <NavLink to="/service/pay" className="footer__help-link white">
                  Оплата и Доставка
                </NavLink>
              </li>
              <li className="footer__help-item">
                <NavLink
                  to="/service/warranty"
                  className="footer__help-link white"
                >
                  Гарантия и Возврат
                </NavLink>
              </li>
              <li className="footer__help-item">
                <NavLink
                  to="/service/bonuse"
                  className="footer__help-link white"
                >
                  Бонусы
                </NavLink>
              </li>
            </ul>
          </div>
          <div className="col col-3 footer__showroom footer__col">
            <h5 className="h4 w">Шоу-рум</h5>
            <div className="footer__showroom-info">
              <p className="sm">
                129272, г. Москва,
                <br /> ул. Трифоновская, 45
              </p>
              <br />
              <p className="sm">
                Пн-Пт: 10:00 – 20:00
                <br />
                Сб-Вс: 11:00 - 19:00
                {/* <br />
                25 марта: 10:00 - 19:00 */}
              </p>
            </div>
          </div>
          <div className="col col-3 col-m-0 footer__map footer__col">
            <a
              className="white"
              target="_blank"
              href={`https://yandex.ru/maps/-/CCU9YXfA1C`}
              rel="noreferrer"
            >
              <img src={map} alt="адрес" width="294px" height="119px" />
              <p className="sm">Проложить маршрут в Яндекс.Карты</p>
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col col-12">
            <div className="rohe">
              <a
                href="https://www.rohe.agency/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  className="rohe__logo"
                  src="/img/rohe-logo.svg"
                  height={20}
                  width={53}
                  alt="Rohe agency logo"
                />
              </a>
              <p className="rohe__text">
                Создано в{" "}
                <a
                  href="https://www.rohe.agency/"
                  className="rohe__link"
                  target="_blank"
                  rel="noreferrer"
                >
                  rohe
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
